import type React from "react";
import { useKey } from "react-use";
import { AnimatePresence, motion } from "framer-motion";

import { createUseStyles } from "@/theme";

const MENU_MARGIN_TOP = 56; // header height
const MENU_PADDING_LEFT = 48;
const MENU_MAX_WIDTH = 400;

const useStyles = createUseStyles(({ sizes, color, zIndex }) => ({
  outerContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: zIndex.modal,
    overflow: "hidden",
    paddingLeft: MENU_PADDING_LEFT,

    [sizes.md]: {
      justifyContent: "flex-end",
      marginTop: MENU_MARGIN_TOP,
      paddingTop: "unset",
      height: `calc(100vh - ${MENU_MARGIN_TOP}px)`,
    },
  },
  container: {
    background: color.gray6,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    bottom: 0,
    right: 0,
    overflowY: "hidden",
    position: "relative",
    maxWidth: MENU_MAX_WIDTH,
  },
  content: {
    height: "100%",
  },
}));

type GenericModalProps = {
  id?: string;
  isOpen?: boolean;
  closeModal?: () => void;
  children: React.ReactNode;
};

const GenericModal: React.FC<GenericModalProps> = ({
  id,
  isOpen,
  closeModal,
  children,
}) => {
  const classes = useStyles();

  useKey("Escape", closeModal);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={classes.outerContainer}
          onClick={closeModal}
          id={id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <motion.div
            className={classes.container}
            onClick={(e) => e.stopPropagation()}
            initial={{ transform: "translateX(100%)" }}
            animate={{ transform: "translateX(0px)" }}
            exit={{ transform: "translateX(100%)" }}
            transition={{
              duration: 0.2,
            }}
          >
            <div className={classes.content}>{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default GenericModal;
