import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import PasswordIcon from "@/assets/svg/password-icon.svg?react";
import { Form, FormPasswordField } from "@/base/components/Form";
import { PASSWORD_FORMAT } from "@/base/components/Form/v1/helpers";
import { Button, H5, Link } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import SimpleModalWrapper from "@/base/components/Modal/SimpleModalWrapper";
import { useUser } from "@/base/components/UserProvider";
import config from "@/constants/config";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { NavigationPath } from "@/constants/navigation";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  forgotPassword: {
    paddingTop: 4,
    paddingBottom: spacing.xl,
  },
}));

const FormSchema = z
  .object({
    oldPassword: z.string(),
    newPassword: z.string().refine((value) => PASSWORD_FORMAT.test(value), {
      params: { i18n: { key: "errors.invalid_password_format" } },
    }),
    newPasswordVerify: z.string(),
  })
  .refine((data) => data.newPassword === data.newPasswordVerify, {
    params: { i18n: { key: "errors.passwords_do_not_match" } },
    path: ["newPasswordVerify"],
  });

type FormSchemaType = z.infer<typeof FormSchema>;

const ChangePasswordPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ changePassword }] = useUser();
  const navigate = useNavigate();
  const [currentModal, setCurrentModal] = useState<"success" | "error">();

  const form = useForm<FormSchemaType>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    watch,
    trigger,
    formState: { isValid, isSubmitting, dirtyFields },
  } = form;

  useEffect(() => {
    const { unsubscribe } = watch((_, { name }) => {
      if (name && ["newPassword", "newPasswordVerify"].includes(name)) {
        if (dirtyFields.newPassword) {
          void trigger(["newPassword"]);
        }

        if (dirtyFields.newPasswordVerify) {
          void trigger(["newPasswordVerify"]);
        }
      }
    });

    return () => unsubscribe();
  }, [watch, trigger, dirtyFields]);

  const onSubmit = async (data: FormSchemaType) => {
    const isSuccess = await changePassword(data);
    if (isSuccess) {
      setCurrentModal("success");
    } else {
      setCurrentModal("error");
    }
  };

  return (
    <Row noMargin justifyCenter>
      <Col col={12} colMd={8} noMargin directionColumn>
        <Row>
          <Col col={12}>
            <H5>{t("field_new_password")}</H5>
          </Col>
        </Row>
        <Form {...form} onSubmitValid={onSubmit}>
          <Row>
            <Col col={12} noMarginBottom>
              <FormPasswordField
                name="oldPassword"
                id="current-password-input"
                label={t("field_current_password_label")}
                placeholder={t("field_current_password_placeholder")}
                autoComplete="current-password"
              />
            </Col>
            <Col col={12} alignEnd className={classes.forgotPassword}>
              <Link
                navigateTo={
                  config.routerConfig.routerMode === "giftCard"
                    ? NavigationPath.ProfileResetPassword
                    : NavigationPath.ResetPassword
                }
                gray1
                underline
                xSmall
              >
                {t("change_password_page_forgot_password_link")}
              </Link>
            </Col>
            <Col col={12}>
              <FormPasswordField
                name="newPassword"
                id="new-password-input"
                label={t("field_new_password_placeholder")}
                placeholder={t("field_new_password_placeholder")}
                autoComplete="new-password"
              />
            </Col>
            <Col col={12}>
              <FormPasswordField
                name="newPasswordVerify"
                id="verify-new-password-input"
                label={t("field_verify_new_password_placeholder")}
                placeholder={t("field_verify_new_password_placeholder")}
                autoComplete="new-password"
              />
            </Col>
            <Col col={12}>
              <Button
                type="submit"
                isDisabled={!isValid}
                prefixIcon={<PasswordIcon />}
                isLoading={isSubmitting}
                analyticsAttributes={
                  GOOGLE_ANALYTICS_ATTRIBUTES.PASSWORD_CHANGE
                }
              >
                {t("field_password_update")}
                <SimpleModalWrapper
                  isOpen={currentModal === "success"}
                  onClose={() => {
                    navigate(NavigationPath.Home);
                  }}
                  title={t("password_updated_success_title")}
                  subTitle={t("password_updated_success_subtitle")}
                  content={t("password_updated_success_content")}
                  analyticsAttributes={
                    GOOGLE_ANALYTICS_ATTRIBUTES.PASSWORD_CHANGE_SUCCESS
                  }
                />
                <SimpleModalWrapper
                  isOpen={currentModal === "error"}
                  onClose={() => {
                    setCurrentModal(undefined);
                  }}
                  title={t("password_updated_fail_title")}
                  subTitle={t("password_updated_fail_subtitle")}
                  content={t("password_updated_fail_content")}
                  analyticsAttributes={
                    GOOGLE_ANALYTICS_ATTRIBUTES.PASSWORD_CHANGE_DECLINE
                  }
                />
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default ChangePasswordPage;
