import type React from "react";

import FooterLink from "@/base/components/Footer/FooterLink";
import InfoBlock from "@/base/components/Footer/InfoBlock";
import ScrollToTopButton from "@/base/components/Footer/ScrollToTopButton";
import { Icon } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { BOTTOM_NAVBAR_HEIGHT } from "@/base/components/TabBar";
import { MARKUS_FOOTER_LOGO_ICON } from "@/constants/blobIcons";
import { useAppSelector } from "@/store";
import { useAppDispatch } from "@/store";
import { fetchFooter, selectFooter } from "@/store/cms";
import { createUseStyles } from "@/theme";

import { DataLoader } from "../Global/DataLoader";

const isExternalURL = (url: string) => {
  try {
    return new URL(url).origin !== location.origin;
  } catch {
    return false;
  }
};

const useStyles = createUseStyles(({ color, spacing }) => ({
  container: {
    background: color.white,
    paddingBottom: spacing.xxl + BOTTOM_NAVBAR_HEIGHT,
    marginTop: "auto",
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const footerStateMachine = useAppSelector(selectFooter);

  return (
    <DataLoader
      name="footer"
      stateMachine={footerStateMachine}
      fetchData={() => dispatch(fetchFooter())}
    >
      {(data) => (
        <div className={classes.container}>
          <Row noMargin>
            <Col alignCenter noMargin>
              <ScrollToTopButton />
            </Col>
            {data.action_items.map((item) => (
              <FooterLink
                key={item.title}
                name={item.title}
                path={item.actionData}
                isExternal={isExternalURL(item.actionData)}
              />
            ))}
            <InfoBlock footer={data} />
            <Col alignCenter>
              <Icon icon={MARKUS_FOOTER_LOGO_ICON} width={130} height={46} />
            </Col>
          </Row>
        </div>
      )}
    </DataLoader>
  );
};

export default Footer;
