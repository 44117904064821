import type React from "react";
import { useEffect } from "react";
import { ErrorBoundary } from "@sentry/react";

import ErrorPage from "@/pages/ErrorPage/ErrorPage";
import type { ApiGetStateMachine } from "@/store/types";
import { shouldLoad } from "@/store/utils";

import { RowBreak } from "../Layout";

import Loader from "./Loader";

type CommonProps<TData> = {
  name: string;
  stateMachine: ApiGetStateMachine<TData>;
  fetchData: () => void;
};

type DisabledProps<TData> = CommonProps<TData> & {
  isDisabled: true;
  children: React.ReactNode;
};

type EnabledProps<TData> = CommonProps<TData> & {
  isDisabled?: false;
  children: ((data: TData) => React.ReactNode) | React.ReactNode;
};

type DataLoaderProps<TData> = DisabledProps<TData> | EnabledProps<TData>;

const Content = <TData,>(props: DataLoaderProps<TData>): JSX.Element => {
  useEffect(() => {
    if (!props.isDisabled && shouldLoad(props.stateMachine)) {
      props.fetchData();
    }
  }, [props.stateMachine, props.isDisabled]);

  if (props.isDisabled) {
    return <>{props.children}</>;
  }

  if (
    props.stateMachine.status === "idle" ||
    props.stateMachine.status === "pending" ||
    props.stateMachine.status === "failedPending"
  )
    return (
      <>
        <RowBreak />
        <Loader isMinified />
      </>
    );

  if (props.stateMachine.status === "failed") {
    throw new Error(`Data loader failed to load data for ${props.name}`);
  }

  if (props.children instanceof Function) {
    return <>{props.children({ ...props.stateMachine })}</>;
  }

  return <>{props.children}</>;
};

export const DataLoader = <TData,>(
  props: DataLoaderProps<TData>,
): JSX.Element => {
  return (
    <ErrorBoundary fallback={(props) => <ErrorPage {...props} />}>
      <Content {...props} />
    </ErrorBoundary>
  );
};
