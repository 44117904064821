import type React from "react";
import { useTranslation } from "react-i18next";

import type { Footer } from "@/api/interfaces/footer";
import { Link, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  container: {
    paddingTop: spacing.xxl,
    paddingBottom: spacing.xxl,
  },
}));

type InfoBlockProps = {
  footer: Footer;
};

const InfoBlock: React.FC<InfoBlockProps> = ({ footer }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Row className={classes.container}>
      <Col col={12} alignCenter hide={!footer.support_contact_email}>
        <Link brandColor bold mailTo={footer.support_contact_email}>
          {footer.support_contact_email}
        </Link>
      </Col>
      <Col col={12} alignCenter hide={!footer.business_address}>
        <P gray3 bold>
          {footer.business_address}
        </P>
      </Col>
      <Col col={12} alignCenter>
        <P gray4 bold>
          {t("footer_copyright", { year: new Date().getFullYear() })}
        </P>
      </Col>
    </Row>
  );
};

export default InfoBlock;
