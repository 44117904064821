import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isPresent } from "@apl-digital/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { format, sub } from "date-fns";
import parsePhoneNumber from "libphonenumber-js";
import { z } from "zod";

import { HttpStatus, MIN_REQUIRED_AGE } from "@/api/constants";
import UserController from "@/api/controller/userController";
import VerificationCodeController from "@/api/controller/verificationCodeController";
import type { IRegionPersonalCodeCategory } from "@/api/interfaces/countriesLayout";
import { PersonalCodeCategory } from "@/api/interfaces/userLayouts";
import { VerificationAction } from "@/api/interfaces/verificationCodeLayout";
import {
  Form,
  FormDatePicker,
  FormPasswordField,
  FormTextField,
} from "@/base/components/Form";
import CountrySelect from "@/base/components/Form/v1/CountrySelect";
import { PASSWORD_FORMAT } from "@/base/components/Form/v1/helpers";
import PhoneField from "@/base/components/Form/v1/PhoneField";
import { Button, H5, Link, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import SimpleModalWrapper from "@/base/components/Modal/SimpleModalWrapper";
import { useUser } from "@/base/components/UserProvider";
import {
  clearStorage,
  getStorage,
  LocalStorageKey,
} from "@/base/utils/storage";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import {
  Action,
  ErrorType,
  NavigationPath,
  VerificationCode,
} from "@/constants/navigation";
import NewsletterCheckboxes from "@/pages/UserRegistration/components/NewsletterCheckboxes";
import { verifyVerificationCode } from "@/pages/VerificationCodeErrorPage/helper/VerificationCodeHelper";
import { useAppSelector } from "@/store/hooks";
import {
  selectCurrentRegion,
  selectEnabledRegions,
} from "@/store/region/selectors";
import { createUseStyles } from "@/theme";

import PersonalCodeSelect from "./components/PersonalCodeSelect";

const useStyles = createUseStyles(({ spacing, sizes }) => ({
  leftButton: {
    [sizes.md]: {
      paddingRight: spacing.xxl,
    },
  },
  rightButton: {
    [sizes.md]: {
      paddingLeft: spacing.xxl,
    },
  },
  textSpacing: {
    marginTop: spacing.xs,
    marginBottom: spacing.xs,
  },
}));

const CommonFormSchema = z.object({
  keywords: z.array(z.object({ key: z.string() })),
  password: z.string().refine((value) => PASSWORD_FORMAT.test(value), {
    params: { i18n: { key: "errors.invalid_password_format" } },
  }),
  passwordVerify: z.string(),
  phoneNumber: z
    .string()
    .refine((value) => parsePhoneNumber(value)?.isValid(), {
      params: { i18n: { key: "errors.invalid_phone_number" } },
    }),
  preferredClientTerritoryId: z.string(),
});

const FormWithEstonianCodeSchema = CommonFormSchema.extend({
  personalCodeCategory: z.literal(PersonalCodeCategory.EstonianIdCode),
  personalCode: z.string().trim().length(11),
});

const FormWithIcelandicSocialSecurityNumberSchema = CommonFormSchema.extend({
  personalCodeCategory: z.literal(
    PersonalCodeCategory.IcelandicSocialSecurityNumber,
  ),
  personalCode: z.string().trim(),
});

const FormWithUnknownSchema = CommonFormSchema.extend({
  personalCodeCategory: z.literal(PersonalCodeCategory.Unknown),
  personalCode: z.string().trim(),
});

const FormWithMalteseIdCodeSchema = CommonFormSchema.extend({
  personalCodeCategory: z.literal(PersonalCodeCategory.MalteseIdCode),
  personalCode: z.string().trim(),
});

const FormWithBirthdaySchema = CommonFormSchema.extend({
  personalCodeCategory: z.literal(PersonalCodeCategory.None),
  birthDay: z.coerce.date().max(sub(new Date(), { years: MIN_REQUIRED_AGE })),
});

const FormSchema = z
  .discriminatedUnion("personalCodeCategory", [
    FormWithEstonianCodeSchema,
    FormWithIcelandicSocialSecurityNumberSchema,
    FormWithUnknownSchema,
    FormWithMalteseIdCodeSchema,
    FormWithBirthdaySchema,
  ])
  .refine((data) => data.password === data.passwordVerify, {
    params: { i18n: { key: "errors.passwords_do_not_match" } },
    path: ["passwordVerify"],
  });

type FormSchemaType = z.infer<typeof FormSchema>;

const CompleteUserRegistrationPage = () => {
  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [{ logIn }] = useUser();

  const enabledRegions = useAppSelector(selectEnabledRegions);
  const defaultApolloClubCountry = useAppSelector(selectCurrentRegion);

  const [currentModal, setCurrentModal] = useState<"success" | "error">();

  const [personalCodeCategories, setPersonalCodeCategories] = useState<
    IRegionPersonalCodeCategory[]
  >([]);
  const [isPersonalCodeSelectionEnabled, setIsPersonalCodeSelectionEnabled] =
    useState(false);

  const firstNameFromStorage = getStorage(
    LocalStorageKey.REGISTRATION_FIRST_NAME,
  );

  const verificationCode = searchParams.get(VerificationCode);

  const form = useForm<FormSchemaType>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
    defaultValues: {
      preferredClientTerritoryId: defaultApolloClubCountry?.id,
      personalCodeCategory:
        defaultApolloClubCountry?.personalCodeCategories?.at(0)
          ?.personalCodeCategory || PersonalCodeCategory.None,
      keywords: [],
    },
  });

  const {
    trigger,
    register,
    setValue,
    resetField,
    watch,
    formState: { errors, isSubmitted, isSubmitting, dirtyFields },
    control,
  } = form;

  const { fields, append, remove } = useFieldArray({
    name: "keywords",
    control,
  });

  const personalCodeCategory = watch("personalCodeCategory");
  const preferredClientTerritoryId = watch("preferredClientTerritoryId");

  useEffect(() => {
    const searchVerificationCode = searchParams.get(VerificationCode);

    void verifyVerificationCode(searchVerificationCode).then(
      ({ isCodeValid, isCodeUsed, status }) => {
        if (isCodeUsed) {
          navigate(NavigationPath.Home);
          return;
        }
        if (!isCodeValid) {
          navigate({
            pathname: NavigationPath.VerificationCodeErrorPage,
            search: `${VerificationCode}=${searchVerificationCode}&${Action}=${VerificationAction.REGISTRATION}&${ErrorType}=${status}`,
          });
        }
      },
    );
  }, [searchParams]);

  useEffect(() => {
    if (enabledRegions && preferredClientTerritoryId) {
      const selectedRegion = enabledRegions.find(
        (region) => region.id === preferredClientTerritoryId,
      );
      setIsPersonalCodeSelectionEnabled(
        Boolean(selectedRegion?.isPersonalCodeSelectionEnabled),
      );
      setPersonalCodeCategories(selectedRegion?.personalCodeCategories ?? []);
    }
  }, [enabledRegions, preferredClientTerritoryId]);

  useEffect(() => {
    if (personalCodeCategories) {
      resetField("personalCodeCategory");
    }
  }, [personalCodeCategories]);

  const onSubmit = async (data?: FormSchemaType) => {
    if (!isPresent(verificationCode)) {
      throw new Error("Verification code is missing");
    }

    if (!isPresent(data)) {
      throw new Error("Body is missing");
    }

    const body =
      data.personalCodeCategory === PersonalCodeCategory.None
        ? {
            ...data,
            birthDay: format(data.birthDay, "dd/MM/yyyy"),
          }
        : data;

    const response = await UserController.registerComplete({
      body: {
        ...body,
        verificationCode,
      },
    });

    if (!response.isResponseOk) {
      setCurrentModal("error");

      if (
        response.responseStatus === HttpStatus.BAD_REQUEST &&
        isPresent(response.response)
      ) {
        for (const { parameterName, message, code } of response.response
          .validationErrors) {
          form.setError(parameterName as keyof FormSchemaType, {
            type: "custom",
            message: t(`${parameterName}.${code}`, message),
          });
        }
      }

      return;
    }

    await VerificationCodeController.expireCode({
      body: { code: verificationCode },
    });

    clearStorage(LocalStorageKey.REGISTRATION_FIRST_NAME);
    setCurrentModal("success");
  };

  const hasError = (inputName: keyof FormSchemaType) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  // When NONE category is selected or personal code selection is disabled, display DateInput for birthDay.
  const shouldShowPersonalCodeField =
    isPersonalCodeSelectionEnabled && personalCodeCategory
      ? PersonalCodeCategory.None !== personalCodeCategory
      : false;

  const onNewsletterCheck = (isSelected: boolean, id: string): void => {
    if (isSelected) {
      append({
        key: id,
      });
    } else {
      const indexToRemove = fields.findIndex((el) => el.key === id);
      if (indexToRemove !== -1) {
        remove(indexToRemove);
      }
    }
  };

  const resetKeywords = () => {
    setValue("keywords", []);
  };

  useEffect(() => {
    if (shouldShowPersonalCodeField) {
      resetField("birthDay", { defaultValue: undefined });
    } else {
      resetField("personalCode", { defaultValue: undefined });
    }
  }, [personalCodeCategory]);

  useEffect(() => {
    const { unsubscribe } = watch((_, { name }) => {
      if (name && ["password", "passwordVerify"].includes(name)) {
        if (dirtyFields.password) {
          void trigger(["password"]);
        }

        if (dirtyFields.passwordVerify) {
          void trigger(["passwordVerify"]);
        }
      }
    });

    return () => unsubscribe();
  }, [watch, trigger, dirtyFields]);

  const { ref: phoneNumberRef, ...phoneNumberProps } = register("phoneNumber");

  return (
    <Row noMargin>
      <Col col={12} noMargin directionColumn>
        <Row>
          <Col col={12} directionColumn>
            {firstNameFromStorage && (
              <H5>
                {t("welcome_msg_with_name", {
                  name: firstNameFromStorage,
                })}
              </H5>
            )}
            {!firstNameFromStorage && <H5>{t("welcome_msg")}</H5>}
            <P>{t("apollo_benefits_register_msg")}</P>
          </Col>
        </Row>
      </Col>
      <Form {...form} onSubmitValid={onSubmit}>
        <Row>
          <Col col={12} colMd={6} noMargin directionColumn>
            <Col fullWidth>
              <PhoneField
                label={t("phone_number")}
                helperText={hasError("phoneNumber") && t("field_phone_error")}
                hasError={hasError("phoneNumber")}
                onEnter={{ trigger, name: "apolloClubCountry" }}
                innerRef={phoneNumberRef}
                {...phoneNumberProps}
              />
            </Col>

            {/*Feature util to unlock regions by one*/}
            <Col
              hide={!isPresent(enabledRegions) || enabledRegions.length === 0}
              fullWidth
              noMarginBottom
            >
              <CountrySelect
                regions={enabledRegions ?? []}
                id="preferred-country-select"
                label={t("apollo_club_country")}
                helperText={
                  hasError("preferredClientTerritoryId") &&
                  t("field_apolloClubCountry_error")
                }
                hasError={hasError("preferredClientTerritoryId")}
                currentValue={preferredClientTerritoryId}
                setCurrentValue={({ value }) => {
                  setValue("preferredClientTerritoryId", value, {
                    shouldDirty: true,
                  });
                }}
              />
            </Col>

            <Col
              hide={!isPersonalCodeSelectionEnabled}
              fullWidth
              noVerticalMargin
            >
              <PersonalCodeSelect
                id="personal-code-country-select"
                label={t("personal_code_country")}
                helperText={
                  hasError("personalCodeCategory") &&
                  t("field_personalCodeCountry_error")
                }
                hasError={hasError("personalCodeCategory")}
                currentCountryId={preferredClientTerritoryId}
                currentValue={personalCodeCategory}
                setCurrentValue={({ value }) => {
                  setValue(
                    "personalCodeCategory",
                    value as PersonalCodeCategory,
                    {
                      shouldDirty: true,
                    },
                  );
                }}
              />
            </Col>

            <Col hide={!shouldShowPersonalCodeField} fullWidth>
              <FormTextField
                name="personalCode"
                id="personal-code-input-bar"
                label={t("personal_code")}
                placeholder={t("personal_code")}
                helperText={t("personal_code_helper_text")}
              />
            </Col>

            <Col hide={shouldShowPersonalCodeField} fullWidth>
              <FormDatePicker
                id="birthday-input"
                name="birthDay"
                label={t("birth_date")}
                maxDate={sub(new Date(), { years: MIN_REQUIRED_AGE })}
                openTo="year"
                views={["year", "month", "day"]}
                disableFuture
              />
            </Col>

            <Col fullWidth>
              <FormPasswordField
                name="password"
                id="password-input"
                label={t("field_password_label")}
                placeholder={t("field_password_placeholder")}
                autoComplete="new-password"
              />
            </Col>

            <Col fullWidth>
              <FormPasswordField
                name="passwordVerify"
                id="verify-password-input"
                label={t("field_verify_new_password_placeholder")}
                placeholder={t("field_verify_new_password_placeholder")}
                autoComplete="new-password"
              />
            </Col>
          </Col>

          <Col col={12} colMd={6} noMargin directionColumn>
            <Row>
              <P xSmall bold className={classes.textSpacing} gray3>
                {t("profiling_agree_message")}
              </P>
              <Link
                small
                target="_blank"
                className={classes.textSpacing}
                brandColor
                href={t("profiling_info_checkbox_link_url")}
              >
                {t("profiling_info_checkbox_link_text")}
              </Link>
              {preferredClientTerritoryId && (
                <Row noMargin>
                  <Col col={12} noMarginTop>
                    <NewsletterCheckboxes
                      countryId={preferredClientTerritoryId}
                      onChange={onNewsletterCheck}
                      reset={resetKeywords}
                    />
                  </Col>
                </Row>
              )}
            </Row>
          </Col>
          <Col col={12} noMargin directionColumn>
            <Row>
              <Col aboveMd colMd={6} orderMd={2} />
              <Col
                col={12}
                colMd={6}
                orderMd={4}
                className={classes.rightButton}
              >
                <Button
                  type="submit"
                  id="submit-btn"
                  isLoading={isSubmitting}
                  analyticsAttributes={
                    GOOGLE_ANALYTICS_ATTRIBUTES.REGISTER_BUTTON_COMPLETE
                  }
                >
                  {t("register_user")}
                  <SimpleModalWrapper
                    isOpen={currentModal === "success"}
                    onClose={() => {
                      navigate(NavigationPath.Home);
                    }}
                    title={t("registration_step2_success_modal_title")}
                    subTitle={t("registration_step2_success_modal_subtitle")}
                    content={t("registration_step2_success_modal_content")}
                  />
                  <SimpleModalWrapper
                    isOpen={currentModal === "error"}
                    onClose={() => {
                      setCurrentModal(undefined);
                    }}
                    title={t("registration_step2_error_modal_title")}
                    subTitle={t("registration_step2_error_modal_subtitle")}
                    content={t("registration_step2_error_modal_content")}
                  />
                </Button>
              </Col>
              <Col belowSm col={12} alignCenter>
                <P xSmall bold>
                  {t("account_exists_message")}
                </P>
              </Col>
              <Col aboveMd colMd={6} orderMd={1} directionColumn>
                <P xSmall bold>
                  {t("account_exists_message")}
                </P>
              </Col>
              <Col
                col={12}
                colMd={6}
                orderMd={3}
                className={classes.leftButton}
              >
                <Button
                  transparentBrand
                  action={logIn}
                  id="sign-in-btn"
                  analyticsAttributes={
                    GOOGLE_ANALYTICS_ATTRIBUTES.SIGN_IN_BUTTON_COMPLETE
                  }
                >
                  {t("sign_in_button")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default CompleteUserRegistrationPage;
