import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";

import { LayoutWrapper } from "@/base/components/Global";
import MediaQueryProvider from "@/base/components/MediaQueryProvider";
import UserProvider from "@/base/components/UserProvider";
import { isInIframe } from "@/base/utils/iframeUtils";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { fetchRegions } from "@/store/region";

import { DataLoader } from "../Global/DataLoader";

const DebugMenu = React.lazy(() => import("@/base/components/DebugMenu"));

const RootProvider: React.FC = () => {
  const dispatch = useAppDispatch();
  const regions = useAppSelector((state) => state.region.regions);

  return (
    <DataLoader
      name="regions"
      stateMachine={regions}
      fetchData={() => dispatch(fetchRegions())}
      isDisabled={isInIframe()}
    >
      <MediaQueryProvider>
        <LayoutWrapper>
          <UserProvider>
            <Outlet />
          </UserProvider>
        </LayoutWrapper>
      </MediaQueryProvider>
      <ScrollRestoration />
      {import.meta.env.VITE_DEBUG_MODE === "true" && <DebugMenu />}
    </DataLoader>
  );
};

export default RootProvider;
