import type React from "react";
import { useTranslation } from "react-i18next";

import UpArrowIcon from "@/assets/svg/up-arrow.svg?url";
import { Icon, Link } from "@/base/components/Global";
import { handleScrollToTop } from "@/base/utils/scrollHelpers";
import { createUseStyles, getTheme } from "@/theme";

const useStyles = createUseStyles(({ color, spacing }) => ({
  link: {
    background: color.gray5,
    width: "100%",
    justifyContent: "center",
    padding: spacing.s,
  },
}));

const ScrollToTop: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = getTheme();

  return (
    <Link
      flex
      small
      gray3
      bold
      action={handleScrollToTop}
      className={classes.link}
      suffixIcon={
        <Icon
          fill={theme.color.gray3}
          icon={UpArrowIcon}
          height={8}
          width={8}
        />
      }
    >
      {t("footer_scroll_top")}
    </Link>
  );
};

export default ScrollToTop;
