import type React from "react";

import { SectionType } from "@/api/interfaces/pageLayout";
import PerkBlock from "@/base/blocks/PerkBlock";
import { CmsBlocks } from "@/base/components/Global";
import { CmsPage } from "@/base/components/Global/cmsPage";
import BenefitsBlock from "@/pages/HomePage/components/BenefitsBlock/BenefitsBlock";
import ApolloClubCard from "@/pages/HomePage/components/ClaimYourClubCard";
import {
  getBlock,
  isLastBlock,
} from "@/pages/HomePage/components/helper/cmsHelper";
import IntroductionBlocks from "@/pages/HomePage/components/IntroductionBlocks";
import LocationsBlock from "@/pages/HomePage/components/LocationsBlock";
import SignInBlock from "@/pages/HomePage/components/SignInBlock";
import { fetchPublicHomePage } from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

const PublicHomePage: React.FC = () => {
  const publicHomePage = useAppSelector((state) => state.cms.publicHomePage);
  const dispatch = useAppDispatch();

  return (
    <CmsPage
      name="PublicHomePage"
      pageStateMachine={publicHomePage}
      fetchPage={() => dispatch(fetchPublicHomePage())}
    >
      {({ page }) => (
        <CmsBlocks>
          {getBlock(<SignInBlock />, -1, false, true)}
          {page.components.map((c, idx, arr) => {
            const isSmall = idx === 0;
            switch (c.type) {
              case SectionType.LOCATION:
                return getBlock(
                  <LocationsBlock block={c} />,
                  idx,
                  isLastBlock(arr.length, idx),
                  isSmall,
                );
              case SectionType.BENEFITS_DETAILS:
                return getBlock(
                  <IntroductionBlocks block={c} />,
                  idx,
                  isLastBlock(arr.length, idx),
                  isSmall,
                );
              case SectionType.BENEFITS:
                return getBlock(
                  <BenefitsBlock block={c} />,
                  idx,
                  isLastBlock(arr.length, idx),
                  isSmall,
                );
              case SectionType.GIFT_CARDS:
                return getBlock(
                  <PerkBlock block={c} />,
                  idx,
                  isLastBlock(arr.length, idx),
                  isSmall,
                );
              case SectionType.CLUB_CARD:
                return getBlock(
                  <ApolloClubCard block={c} />,
                  idx,
                  isLastBlock(arr.length, idx),
                  isSmall,
                );
              default:
                return null;
            }
          })}
        </CmsBlocks>
      )}
    </CmsPage>
  );
};

export default PublicHomePage;
