const getAppContainer = () => {
  const appContainer = document.querySelector("#app-container");

  if (!appContainer) {
    throw new Error("App container not found");
  }

  return appContainer;
};

export const scrollToWithOffset = (targetId: string) => {
  try {
    const element = document.querySelector(`#${targetId}`);

    element?.scrollIntoView({ behavior: "smooth" });
  } catch (error) {
    console.warn(error);
  }
};

export const handleScrollToTop = (smooth = true) => {
  try {
    const appContainer = getAppContainer();

    if (appContainer.scrollTop !== 0) {
      appContainer.scrollTo({
        top: 0,
        behavior: smooth ? "smooth" : "auto",
      });
    }
  } catch (error) {
    console.warn(error);
  }
};
