import { isPresent } from "@apl-digital/utils";

import type { Language } from "@/api/interfaces/countriesLayout";
import { getSessionStorage, SessionStorageKey } from "@/base/utils/storage";
import { selectAllLocales } from "@/store/region/selectors";
import type { RootState } from "@/store/slices";

export const selectUserLanguage = (state: RootState): Language | null => {
  const userLocalId =
    state.user.userData.status === "succeeded" && state.user.userData?.localeId;

  if (isPresent(userLocalId)) {
    const locales = selectAllLocales(state);

    if (isPresent(locales)) {
      const userLocale = locales.find((locale) => locale.id === userLocalId);

      if (isPresent(userLocale)) {
        return userLocale.code;
      }
    }
  }

  return null;
};

export const getDomainLanguage = (): Language | null => {
  const host = globalThis.location.host;
  if (host.includes(".ee")) return "et";
  if (host.includes(".fi")) return "fi";
  if (host.includes(".lv")) return "lv";
  if (host.includes(".lt")) return "lt";
  return null;
};

export const selectCurrentLanguage = (state: RootState): Language => {
  const overrideLanguage = state.language.languageOverride;

  if (isPresent(overrideLanguage)) {
    return overrideLanguage;
  }

  const selectedLanguage = state.language.selectedLanguage;

  if (isPresent(selectedLanguage)) {
    return selectedLanguage;
  }

  const userLanguage = selectUserLanguage(state);

  if (isPresent(userLanguage)) {
    return userLanguage;
  }

  const storedLanguage = getSessionStorage(SessionStorageKey.LANGUAGE_CURRENT);

  if (isPresent(storedLanguage)) {
    return storedLanguage;
  }

  const domainLangauge = getDomainLanguage();

  if (isPresent(domainLangauge)) {
    return domainLangauge;
  }

  return "en";
};
