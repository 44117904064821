import type React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isPresent } from "@apl-digital/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { format, sub } from "date-fns";
import { z } from "zod";

import { MIN_REQUIRED_AGE } from "@/api/constants";
import {
  type IUserData,
  PersonalCodeCategory,
} from "@/api/interfaces/userLayouts";
import { Form, FormDatePicker, FormTextField } from "@/base/components/Form";
import EditFormPage from "@/base/components/Form/v1/EditFormPage";
import Button from "@/base/components/Global/Button";
import { DataLoader } from "@/base/components/Global/DataLoader";
import P from "@/base/components/Global/P";
import { Col, Row } from "@/base/components/Layout";
import ModalArray from "@/base/components/Modal/ModalArray";
import { useUser } from "@/base/components/UserProvider";
import { PROFILE_ICON } from "@/constants/blobIcons";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { NavigationPath } from "@/constants/navigation";
import PersonalCodeSelect from "@/pages/UserRegistration/components/PersonalCodeSelect";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { selectHasPersonalCodeCategories } from "@/store/region";
import { fetchUserData, selectUserData } from "@/store/user";

const CommonFormSchema = z.object({
  birthDay: z.coerce
    .date()
    .max(sub(new Date(), { years: MIN_REQUIRED_AGE }))
    .optional(),
});

const FormWithEstonianCodeSchema = CommonFormSchema.extend({
  personalCodeCategory: z.literal(PersonalCodeCategory.EstonianIdCode),
  personalCode: z.string().trim().length(11),
});

const FormWithIcelandicSocialSecurityNumberSchema = CommonFormSchema.extend({
  personalCodeCategory: z.literal(
    PersonalCodeCategory.IcelandicSocialSecurityNumber,
  ),
  personalCode: z.string().trim(),
});

const FormWithUnknownSchema = CommonFormSchema.extend({
  personalCodeCategory: z.literal(PersonalCodeCategory.Unknown),
  personalCode: z.string().trim(),
});

const FormWithMalteseIdCodeSchema = CommonFormSchema.extend({
  personalCodeCategory: z.literal(PersonalCodeCategory.MalteseIdCode),
  personalCode: z.string().trim(),
});

const FormWithBirthdaySchema = z.object({
  personalCodeCategory: z.literal(PersonalCodeCategory.None),
  birthDay: z.coerce.date().max(sub(new Date(), { years: MIN_REQUIRED_AGE })),
});

const FormSchema = z.discriminatedUnion("personalCodeCategory", [
  FormWithEstonianCodeSchema,
  FormWithIcelandicSocialSecurityNumberSchema,
  FormWithUnknownSchema,
  FormWithMalteseIdCodeSchema,
  FormWithBirthdaySchema,
]);

type FormType = z.infer<typeof FormSchema>;

type EditPersonalFormProps = {
  userData: IUserData;
};

const EditPersonalForm: React.FC<EditPersonalFormProps> = ({ userData }) => {
  const [{ updateProfile }] = useUser();
  const { t } = useTranslation();
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const navigate = useNavigate();

  const hasPersonalCodeCategories = useAppSelector(
    selectHasPersonalCodeCategories,
  );

  const personalCodeField = userData?.personalCode
    ? `${userData?.personalCode.slice(0, 2)}**********`
    : undefined;

  const defaultValues = {
    birthDay: userData.birthDay ? new Date(userData.birthDay) : undefined,
    personalCodeCategory: PersonalCodeCategory.None,
    personalCode: personalCodeField,
  };

  const form = useForm<FormType>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    formState: { errors, isValid, isSubmitted, isDirty },
  } = form;

  const onSubmit = async (data?: FormType) => {
    if (!isPresent(data)) {
      throw new Error("Form data is missing");
    }

    const birthDay =
      [
        isPresent(data.birthDay) && format(data.birthDay, "dd/MM/yyyy"),
        !data.birthDay &&
          userData?.birthDay &&
          format(userData.birthDay, "dd/MM/yyyy"),
      ].find(Boolean) || undefined;

    const isSuccess = await updateProfile({
      ...data,
      birthDay: birthDay,
    });

    if (isSuccess) {
      setCurrentModal("editSuccessModal");
    } else {
      setCurrentModal("editErrorModal");
    }
  };

  const currentPersonalCodeCountry = watch("personalCodeCategory");

  const hasError = (inputName: keyof FormType) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  return (
    <EditFormPage
      form={{
        headingIconUrl: PROFILE_ICON,
        headingTitle: t("personal_data_title"),
        headingSubTitle: t("personal_data_subtitle"),
      }}
    >
      <ModalArray
        modals={{
          editSuccessModal: {
            title: t("modal_personal_data_edited_title"),
            subTitle: t("modal_personal_data_edited_subtitle"),
            content: t("modal_personal_data_edited_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.PERSONAL_DATA_CHANGE_SUCCESS,
          },
          editErrorModal: {
            title: t("modal_profile_edit_error_title"),
            subTitle: t("modal_profile_edit_error_subtitle"),
            content: t("modal_profile_edit_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.PERSONAL_DATA_CHANGE_DECLINE,
          },
        }}
        currentModal={currentModal}
        onClose={(modal) => {
          setCurrentModal(null);
          if (modal === "editSuccessModal") navigate(NavigationPath.Profile);
        }}
      />
      <Col col={12} noMargin directionColumn alignCenter>
        <Form {...form} onSubmitValid={onSubmit}>
          <Row noMargin>
            <Col col={12}>
              <FormDatePicker
                id="birthday-input"
                name="birthDay"
                label={t("birth_date")}
                maxDate={sub(new Date(), { years: MIN_REQUIRED_AGE })}
                disabled={!!defaultValues.birthDay}
                helperText={
                  isPresent(defaultValues.birthDay) &&
                  t("why_is_birth_date_asked")
                }
                openTo="year"
                views={["year", "month", "day"]}
                disableFuture
              />
            </Col>

            <Col col={12} directionColumn hide={!hasPersonalCodeCategories}>
              <Col col={12} noHorizontalMargin>
                <P gray1>{t("personal_id")}</P>
              </Col>

              <Col
                hide={isPresent(personalCodeField)}
                noHorizontalMargin
                fullWidth
              >
                <PersonalCodeSelect
                  id="personal-code-country-select"
                  label={t("personal_code_country")}
                  helperText={
                    hasError("personalCodeCategory") &&
                    t("field_personalCodeCountry_error")
                  }
                  hasError={hasError("personalCodeCategory")}
                  currentCountryId={userData?.regionId}
                  setCurrentValue={({ value }) => {
                    setValue(
                      "personalCodeCategory",
                      value as PersonalCodeCategory,
                      {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      },
                    );
                  }}
                />
              </Col>

              <Col
                hide={
                  !isPresent(personalCodeField) &&
                  currentPersonalCodeCountry === PersonalCodeCategory.None
                }
                noHorizontalMargin
                fullWidth
              >
                <FormTextField
                  id="personalCode-input"
                  name="personalCode"
                  label={t("field_personalCode")}
                  disabled={!!personalCodeField}
                  helperText={
                    isPresent(personalCodeField) &&
                    t("personal_code_already_set")
                  }
                />
              </Col>
            </Col>

            <Col col={12}>
              <Button
                type="submit"
                transparentBrand
                isDisabled={!isValid || !isDirty}
                analyticsAttributes={
                  GOOGLE_ANALYTICS_ATTRIBUTES.PERSONAL_DATA_CHANGE
                }
              >
                {t("action_confirm")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </EditFormPage>
  );
};

const EditPersonalData: React.FC = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUserData);

  return (
    <DataLoader
      name="userData"
      stateMachine={userData}
      fetchData={() => dispatch(fetchUserData())}
    >
      {(user) => <EditPersonalForm userData={user} />}
    </DataLoader>
  );
};

export default EditPersonalData;
