import * as yup from "yup";

import type { Validation } from "@/base/components/FormProvider/types";

const PHONE_MIN_CHARS = 7;
const PHONE_FORMAT =
  /^\+((?:9[679]|8[0357-9]|6[7-9]|5[09]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[07]|7|1)(?:\W*\d){0,13}\d$/g;

export const PASSWORD_FORMAT =
  /^(?=.*\d)(?=.*[A-Z])[\w!#$%&'()*+,.<=>?@[\]^|~\-£]{8,50}$/;
/* eslint-disable unicorn/no-thenable */
const validation = {
  required: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .trim()
    .required()
    .test("len", `Must be more than ${PHONE_MIN_CHARS} chars`, (val) =>
      val ? val.length >= PHONE_MIN_CHARS : false,
    )
    .matches(PHONE_FORMAT, "Phone format is not valid"),
  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      PASSWORD_FORMAT,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    ),
  trueRequired: yup.boolean().oneOf([true]),
  repeat: (fieldName: string) =>
    yup
      .string()
      .required()
      .oneOf([yup.ref(fieldName)], "Fields must be the same"),
  jsonValidation: ({
    regex,
    minLen,
    maxLen,
    required,
    email,
    requiredIf,
    trueRequired,
    repeat,
  }: Validation) => {
    let returnYup = yup.string().trim();
    if (regex && regex.length > 0) {
      returnYup = returnYup.matches(
        new RegExp(regex, "g"),
        "regex validation did not pass",
      );
    }
    if (minLen) {
      returnYup = returnYup.test(
        "len",
        `Must be more than ${minLen} chars, is required ${required}`,
        (val) => (val ? val.length >= minLen : !(required && !val)),
      );
    }
    if (maxLen) {
      returnYup = returnYup.test(
        "len",
        `Must be equal or less than ${maxLen} chars`,
        (val) => (val ? val.length <= maxLen : !(required && !val)),
      );
    }
    if (requiredIf) {
      if (Array.isArray(requiredIf)) {
        const fields = requiredIf.map((condition) => condition.field);
        returnYup = returnYup.when(fields, {
          is: (...args: string[]) =>
            args.some(
              (field, index) => field && requiredIf[index].is === field,
            ),
          then: (schema) => schema.required(),
          otherwise: (scheme) => scheme,
        });
      } else {
        returnYup = returnYup.when(requiredIf.field, {
          is: requiredIf.is,
          then: (schema) => schema.required(),
          otherwise: (scheme) => scheme,
        });
      }
    }
    if (required) {
      returnYup = returnYup.required();
    }
    if (email) {
      returnYup = returnYup.email();
    }
    if (trueRequired) {
      return yup.boolean().oneOf([true]);
    }
    if (repeat) {
      return validation.repeat(repeat);
    }
    return returnYup;
  },
};
/* eslint-enable unicorn/no-thenable */

export { validation };
