import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import UserController from "@/api/controller/userController";
import VerificationCodeController from "@/api/controller/verificationCodeController";
import { VerificationAction } from "@/api/interfaces/verificationCodeLayout";
import { Form, FormPasswordField } from "@/base/components/Form";
import { PASSWORD_FORMAT } from "@/base/components/Form/v1/helpers";
import { Button, H5, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import SimpleModalWrapper from "@/base/components/Modal/SimpleModalWrapper";
import { PASSWORD_ICON } from "@/constants/blobIcons";
import {
  Action,
  ErrorType,
  NavigationPath,
  VerificationCode,
} from "@/constants/navigation";
import { verifyVerificationCode } from "@/pages/VerificationCodeErrorPage/helper/VerificationCodeHelper";

const FormSchema = z
  .object({
    password: z.string().refine((value) => PASSWORD_FORMAT.test(value), {
      params: { i18n: { key: "errors.invalid_password_format" } },
    }),
    passwordVerify: z.string(),
  })
  .refine((data) => data.password === data.passwordVerify, {
    params: { i18n: { key: "errors.passwords_do_not_match" } },
    path: ["passwordVerify"],
  });

type FormSchemaType = z.infer<typeof FormSchema>;

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const [currentModal, setCurrentModal] = useState<"success" | "error">();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const form = useForm<FormSchemaType>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    watch,
    trigger,
    formState: { dirtyFields, isSubmitting, isValid },
  } = form;

  useEffect(() => {
    const { unsubscribe } = watch((_, { name }) => {
      if (name && ["password", "passwordVerify"].includes(name)) {
        if (dirtyFields.password) {
          void trigger(["password"]);
        }

        if (dirtyFields.passwordVerify) {
          void trigger(["passwordVerify"]);
        }
      }
    });

    return () => unsubscribe();
  }, [watch, trigger, dirtyFields]);

  const onModalClose = () => {
    setCurrentModal(undefined);
    if (currentModal === "success") {
      navigate(NavigationPath.Home);
    }
  };

  const onSubmit = async (data: FormSchemaType) => {
    const verificationCode = searchParams.get(VerificationCode);

    if (!verificationCode) {
      setCurrentModal("error");
      return;
    }

    const { isResponseOk } = await UserController.resetPassword({
      body: {
        password: data.password,
        verificationCode: verificationCode,
      },
    });
    if (isResponseOk) {
      await VerificationCodeController.expireCode({
        body: { code: verificationCode },
      });
    }
    setCurrentModal(isResponseOk ? "success" : "error");
  };

  useEffect(() => {
    const searchVerificationCode = searchParams.get(VerificationCode);
    void verifyVerificationCode(searchVerificationCode).then(
      ({ isCodeValid, isCodeUsed, status }) => {
        if (isCodeUsed) {
          navigate(NavigationPath.Home);
          return;
        }
        if (!isCodeValid) {
          navigate({
            pathname: NavigationPath.VerificationCodeErrorPage,
            search: `${Action}=${VerificationAction.PASSWORD_RESET}&${ErrorType}=${status}`,
          });
        }
      },
    );
  }, [searchParams]);

  return (
    <>
      <Row>
        <Col fullWidth noMargin alignCenter>
          <Col colMd={9} directionColumn noMargin>
            <Col noMarginBottom>
              <H5 prefixIcon={PASSWORD_ICON} gray1>
                {t("reset_password_verify_page_title")}
              </H5>
            </Col>
            <Col noMarginTop>
              <P noMarginTop small gray3>
                {t("reset_password_verify_page_subtitle")}
              </P>
            </Col>
            <Col fullWidth>
              <P>{t("reset_password_verify_page_content")}</P>
            </Col>
            <Form {...form} onSubmitValid={onSubmit}>
              <Row>
                <Col fullWidth col={12}>
                  <FormPasswordField
                    name="password"
                    id="password-input"
                    label={t("field_new_password_placeholder")}
                    placeholder={t("field_new_password_placeholder")}
                    autoComplete="new-password"
                  />
                </Col>
                <Col fullWidth col={12}>
                  <FormPasswordField
                    name="passwordVerify"
                    id="verify-password-input"
                    label={t("field_verify_new_password_placeholder")}
                    placeholder={t("field_verify_new_password_placeholder")}
                    autoComplete="new-password"
                  />
                </Col>

                <Col fullWidth col={12}>
                  <Button
                    type="submit"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                  >
                    {t("reset_password_verify_page_submit_btn")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Col>
      </Row>
      <SimpleModalWrapper
        isOpen={currentModal === "success"}
        title={t("reset_password_verify_page_success_modal_title")}
        onClose={onModalClose}
        content={t("reset_password_verify_page_success_modal_content")}
      />
      <SimpleModalWrapper
        isOpen={currentModal === "error"}
        title={t("reset_password_verify_page_error_modal_title")}
        onClose={onModalClose}
        content={t("reset_password_verify_page_error_modal_content")}
      />
    </>
  );
};

export default ResetPasswordPage;
