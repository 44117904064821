import type React from "react";
import { useEffect } from "react";
import { ErrorBoundary } from "@sentry/react";

import ErrorPage from "@/pages/ErrorPage/ErrorPage";
import type {
  ApiGetStateMachine,
  StalePendingState,
  StaleState,
  SucceededState,
} from "@/store/types";
import { shouldLoad } from "@/store/utils";

import { RowBreak } from "../Layout";

import Loader from "./Loader";

type ChildrenProps<TData> = {
  page: StaleState<TData> | StalePendingState<TData> | SucceededState<TData>;
};

type CMSPageProps<TData> = {
  name: string;
  pageStateMachine: ApiGetStateMachine<TData>;
  fetchPage: () => void;
  children: ({ page }: ChildrenProps<TData>) => React.ReactNode;
};

const Content = <TData,>({
  name,
  pageStateMachine,
  fetchPage,
  children,
}: CMSPageProps<TData>): JSX.Element => {
  useEffect(() => {
    if (shouldLoad(pageStateMachine)) {
      fetchPage();
    }
  }, [pageStateMachine]);

  if (
    pageStateMachine.status === "idle" ||
    pageStateMachine.status === "pending" ||
    pageStateMachine.status === "failedPending"
  )
    return (
      <>
        <RowBreak />
        <Loader isMinified />
      </>
    );

  if (pageStateMachine.status === "failed") {
    throw new Error(`CMS page failed to load data for ${name}`);
  }

  return <>{children({ page: pageStateMachine })}</>;
};

export const CmsPage = <TData,>(props: CMSPageProps<TData>): JSX.Element => {
  return (
    <ErrorBoundary fallback={(props) => <ErrorPage {...props} />}>
      <Content {...props} />
    </ErrorBoundary>
  );
};
