import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import userController from "@/api/controller/userController";
import CheckboxRow from "@/base/components/Form/v1/CheckboxRow";
import { Button, Icon, Link, P } from "@/base/components/Global";
import { CmsPage } from "@/base/components/Global/cmsPage";
import Section from "@/base/components/Global/Section";
import { Col } from "@/base/components/Layout";
import Row from "@/base/components/Layout/Row";
import ConfirmationModal from "@/base/components/Modal/ConfirmationModal";
import GenericErrorModal from "@/base/components/Modal/GenericErrorModal";
import { useUser } from "@/base/components/UserProvider";
import { exchangeValueFromTranslation } from "@/base/utils/translationHelper";
import { ATTENTION_ICON } from "@/constants/blobIcons";
import config from "@/constants/config";
import { NavigationPath } from "@/constants/navigation";
import {
  fetchBusinesses,
  fetchCloseAccountPage,
  selectBusinesses,
  selectCloseAccountPage,
} from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { selectCurrentRegion } from "@/store/region";
import { selectEmails, selectUserData } from "@/store/user";
import { hasData, shouldLoad } from "@/store/utils";
import { createUseStyles, getTheme } from "@/theme";

const useCloseAccountStyles = createUseStyles(({ spacing, logo }) => ({
  forgotPassword: {
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: spacing.l,
  },
  logoIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: logo.height,
    padding: [spacing.l, spacing.m],
  },
  businesses: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
  },
  container: {
    display: "grid",
    rowGap: spacing.xxl,
  },
}));

const CloseAccount: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useCloseAccountStyles();
  const { t } = useTranslation();
  const { color } = getTheme();
  const [{ logOut }] = useUser();

  const closeAccountPage = useAppSelector(selectCloseAccountPage);
  const userData = useAppSelector(selectUserData);
  const emails = useAppSelector(selectEmails);
  const businesses = useAppSelector(selectBusinesses);
  const currentRegion = useAppSelector(selectCurrentRegion);

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const primaryEmail = emails.find((e) => e.isPrimary)?.email;

  useEffect(() => {
    if (shouldLoad(businesses)) {
      void dispatch(fetchBusinesses());
    }
  }, [businesses]);

  const primaryAction = async () => {
    setIsLoading(true);
    if (userData.status === "succeeded") {
      const { isResponseOk } = await userController.closePersonAccount({
        body: {
          id: userData.guid,
        },
      });

      if (isResponseOk) {
        const postLogoutRedirect = `${config.routerConfig.loyaltyHubUrl}${NavigationPath.Goodbye}`;
        await logOut(postLogoutRedirect);
      } else {
        setErrorModalOpen(true);
      }
    }
    setIsLoading(false);
  };

  return (
    <CmsPage
      name="CloseAccount"
      pageStateMachine={closeAccountPage}
      fetchPage={() => dispatch(fetchCloseAccountPage())}
    >
      {({ page }) => (
        <Row>
          <Col aboveMd colMd={3} />
          <Col noMargin col={12} colMd={6} directionColumn>
            <ConfirmationModal
              isOpen={isConfirmationModalOpen}
              onClose={() => setConfirmationModalOpen(false)}
              title={page.confirmationModal?.title}
              content={page.confirmationModal?.mainText}
              warning={page.confirmationModal?.warning}
              primaryAction={primaryAction}
              primaryTitle={t("closeAccount.confirmationModal.confirmButton")}
              secondaryTitle={t("closeAccount.confirmationModal.cancelButton")}
              areButtonsReversed
              isLoading={isLoading}
            />
            <GenericErrorModal
              cancelAction={() => navigate(NavigationPath.Profile)}
              retryAction={() => {
                setErrorModalOpen(false);
                setConfirmationModalOpen(true);
              }}
              isOpen={isErrorModalOpen}
            />
            <Section
              title={page.title}
              icon={ATTENTION_ICON}
              subTitle={page.subtitle}
              extraPadding={false}
              noAdditionalMargin
            >
              <Row noMargin className={classes.container}>
                {userData.status === "succeeded" && page.mainText && (
                  <P
                    black
                    cmsContent={exchangeValueFromTranslation(
                      page.mainText,
                      "firstName",
                      userData.firstName,
                    )}
                  />
                )}
                {hasData(businesses) && (
                  <Col col={12} noMargin className={classes.businesses}>
                    {businesses.array.map(
                      (business) =>
                        business.regions.some(
                          (region) => region.name === currentRegion?.name,
                        ) && (
                          <div
                            className={classes.logoIcon}
                            key={business.logoURL}
                          >
                            <Icon
                              height="auto"
                              width={60}
                              fill={color.gray2}
                              icon={business.logoURL}
                            />
                          </div>
                        ),
                    )}
                  </Col>
                )}
                <Col col={12} noMargin>
                  <P xSmall gray3 cmsContent={page.additionalText} />
                </Col>
                <Row noMargin>
                  <Col col={12} noMargin>
                    <P xSmall gray3 bold noMargin>
                      {t("closeAccount.emailLabel")}
                    </P>
                  </Col>
                  <Col col={12} noMargin>
                    <P gray1 bold noMargin>
                      {primaryEmail}
                    </P>
                  </Col>
                  {page.forgotPasswordLink && (
                    <Col col={12} className={classes.forgotPassword} noMargin>
                      <P bold gray1 xSmall noMargin>
                        {t("closeAccount.forgotPassword")}
                      </P>
                      <Link
                        bold
                        gray3
                        href={page.forgotPasswordLink?.data}
                        xSmall
                        underline
                      >
                        {page.forgotPasswordLink?.title}
                      </Link>
                    </Col>
                  )}
                </Row>
                <Col col={12} noMargin>
                  <Button action={() => navigate(NavigationPath.Home)}>
                    {t("closeAccount.cancelButton")}
                  </Button>
                </Col>
                <Row noMargin>
                  <Col col={12} noMargin>
                    <P
                      xSmall
                      gray3
                      cmsContent={page.termsAgreement}
                      noVerticalMargin
                    />
                  </Col>
                  {page.links &&
                    page.links.length > 0 &&
                    page.links.map((link) => (
                      <Col key={link?.title} col={12} noVerticalMargin>
                        <Link bold brandColor href={link?.data}>
                          {link?.title}
                        </Link>
                      </Col>
                    ))}
                </Row>

                <Col col={12} noMargin>
                  <CheckboxRow
                    noMargin
                    label={t("closeAccount.confirmCheckbox")}
                    bold={false}
                    slim
                    small
                    noBorder
                    checked={isChecked}
                    onChange={() => {
                      setChecked((prev) => !prev);
                    }}
                  />
                </Col>
                <Col col={12} noMargin>
                  <Button
                    transparentBrand
                    isDisabled={!isChecked || isLoading}
                    action={() => setConfirmationModalOpen(true)}
                  >
                    {t("closeAccount.confirmButton")}
                  </Button>
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      )}
    </CmsPage>
  );
};

export default CloseAccount;
