import * as React from "react";
import { memo } from "react";
const SvgArrowSelect = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "mask",
    {
      id: "8704d67f-d0b2-44fa-bff6-b3588fe319f1-a",
      width: 24,
      height: 24,
      x: 0,
      y: 0,
      maskUnits: "userSpaceOnUse",
      style: {
        maskType: "alpha"
      }
    },
    /* @__PURE__ */ React.createElement("path", { fill: "#C4C4C4", d: "M0 0h24v24H0z" })
  ),
  /* @__PURE__ */ React.createElement(
    "g",
    {
      stroke: "#333",
      strokeLinecap: "round",
      strokeWidth: 2,
      mask: "url(#8704d67f-d0b2-44fa-bff6-b3588fe319f1-a)"
    },
    /* @__PURE__ */ React.createElement("path", { d: "M11.98 15 7 10M16.96 10l-4.98 5" })
  )
);
const Memo = memo(SvgArrowSelect);
export default Memo;
