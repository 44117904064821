import type React from "react";

import { H5, P } from "@/base/components/Global";
import { CmsPage } from "@/base/components/Global/cmsPage";
import { Col, Row } from "@/base/components/Layout";
import { APOLLO_STAR_ICON } from "@/constants/blobIcons";
import {
  fetchTermsAndConditionsPage,
  selectTermsAndConditionsPage,
} from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

const TermsAndConditionsPage: React.FC = () => {
  const termsAndConditionsPage = useAppSelector(selectTermsAndConditionsPage);
  const dispatch = useAppDispatch();

  return (
    <CmsPage
      name="TermsAndConditionsPage"
      pageStateMachine={termsAndConditionsPage}
      fetchPage={() => dispatch(fetchTermsAndConditionsPage())}
    >
      {({ page }) => (
        <Row>
          <Col col={12} directionColumn>
            <H5 prefixIcon={APOLLO_STAR_ICON} gray2>
              {page.title}
            </H5>
            <P cmsContent={page.description} />
          </Col>
        </Row>
      )}
    </CmsPage>
  );
};

export default TermsAndConditionsPage;
